import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../components/layout";
import H3 from "../utils/H3";
import H1 from "../utils/H1";
import Button from "../utils/Button";
import Logo from "../utils/Logo";
import { useForm, Controller } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import Paragraph from "../utils/Paragraph";
import TextArea from "../utils/TextArea";
import Input from "../utils/Input";
import Seo from "../components/seo";
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import addToMailchimp from "gatsby-plugin-mailchimp";
import { AiOutlineCheckCircle } from "@react-icons/all-files/ai/AiOutlineCheckCircle";
const ErrorMessage = ({ children }) => (
  <div className="text-sm text-red" role="alert">
    {children}
  </div>
);
// const PhoneError = ({ children }) => (
//     <div className="my-2 text-xs text-red" role="alert">
//         {children}
//     </div>
// );
const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`md:mb-8 leading-none relative w-auto py-4 md:mt-4 px-6 hover:shadow-lg hover:shadow-darkblue/20 ont-bold rounded-lg flex justify-center items-center bg-darkblue text-white text-base uppercase font-bold cursor-pointer transition-all duration-300 transform ${
      error ? "SubmitButton--error" : ""
    }`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

export default function Contact() {
  const box =
    "w-full flex flex-col justify-center items-start rounded-2xl bg-sectionBlue lg:p-8 md:p-6 p-4";
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(false);
  const [processing, setProcessing] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const onError = (errors, e) => console.log(errors, e);
  const handleForm = async (data) => {
    setProcessing(true);
    console.log(data);
    addToMailchimp(data.email, {
      FIRSTNAME: data.firstname,
      LASTNAME: data.lastname,
      COMPANY: data.company,
      MESSAGE: data.message,
    })
      .then((data) => {
        setStatus(true);
      })
      .catch((error) => {
        setProcessing(false);
        setStatus(false);
        setError(
          "Sorry, there was an error submitting. Please try again later."
        );
      });
    setProcessing(false);
    reset();
  };

  return (
    <Layout>
      <Seo
        title="Get Involved"
        url="https://migrantresilience.org/get-involved"
      />
      {/* <div className="w-full flex flex-col justify-center items-start relative z-0 lg:px-28 md:px-12 px-4 mb-12 my-12 overflow-hidden">
        <h1 className="text-left lg:mt-4 mb-4 text-darkblue md:text-4xl text-2xl font-bold">
          Connect with us
        </h1>
        <img
          src="/images/dot-pattern.png"
          className="absolute top-0 left-20 w-80 object-contain opacity-80 -z-1"
        ></img>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:grid-rows-1 md:grid-rows-2 grid-rows-min lg:gap-8 md:gap-6 gap-4 lg:mt-4">
          <div className={box}>
            <div className="flex flex-col justify-start items-start">
              <Paragraph className="my-2 mr-4 mb-4">
                For careers/openings enquiries, write to
                <br />
                <a
                  href="mailto:careers@jansahasindia.org"
                  target="_blank"
                  className="underline text-blue font-bold"
                >
                  careers@jansahasindia.org
                </a>{" "}
              </Paragraph>
            </div>
          </div>
          <div className={box}>
            <div className="flex flex-col justify-start items-start">
              <Paragraph className="my-2 mr-4 mb-4">
                For companies and investors interested in joining us, please
                contact: <strong>Siri Avalur</strong>,<br />{" "}
                <a
                  href="mailto:siri.a@jansahasfoundation.org"
                  target="_blank"
                  className="underline text-blue font-bold"
                >
                  siri.a@jansahasfoundation.org
                </a>{" "}
              </Paragraph>
            </div>
          </div>
          <div className={box}>
            <div className="flex flex-col justify-start items-start">
              <Paragraph className="my-2 mr-4 mb-4 text-wrap">
                For philanthropy, please contact:{" "}
                <strong>Ashlesha Desai</strong>,
                <br />{" "}
                <a
                  href="mailto:ashlesha.d@jansahasindia.org"
                  target="_blank"
                  className="underline text-blue font-bold text-wrap"
                >
                  ashlesha.d@jansahasindia.org
                </a>{" "}
              </Paragraph>
            </div>
          </div>
        </div>
      </div> */}
      <div className="grid lg:grid-cols-2 grid-cols-1 lg:grid-rows-1 grid-rows-min lg:gap-12 gap-6 w-full lg:my-12 my-6 lg:px-28 md:px-12 px-4">
        <div className="w-full flex flex-col justify-center items-center relative lg:order-first order-last">
          {/* <Banner src="/images/approach-banner.jpg" /> */}
          <div className="w-full bg-sectionBlue md:px-8 px-4 md:pt-8 pt-4 lg:pt-0 rounded-2xl flex flex-col justify-start items-end shadow-darkblue/10">
            {status ? (
              <div className="w-full h-auto flex flex-col justify-center items-center relative m-auto p-12 left-0 top-0 z-10">
                <div className="relative w-full h-full flex flex-col items-center justify-center cursor-pointer transition-all duration-500">
                  <AiOutlineCheckCircle
                    size={50}
                    color={"#0093dd"}
                    className="mb-4"
                  ></AiOutlineCheckCircle>
                  <h1 className="mb-2 text-darkblue font-bold md:text-3xl text-2xl ">
                    Thank you!
                  </h1>
                  <H3
                    color="text-grey "
                    className="font-normal text-center"
                    bottom="mb-4"
                  >
                    Your submission has been received. <br></br>Please check
                    your email for further communication.
                  </H3>
                  {/* <Button type={"internalLink"} to="/">Home</Button> */}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center w-full lg:mt-6 z-1">
                <h1 className="text-left text-darkblue mb-4 text-2xl font-bold uppercase">
                  Get Involved
                </h1>
                <div className="w-full flex flex-col justify-around lg:items-center md:items-end items-start md:mt-0 md:pb-0 pb-8 mt-4">
                  <form
                    onSubmit={handleSubmit(handleForm, onError)}
                    className="flex md:flex-row flex-col justify-center w-full"
                    noValidate
                  >
                    <div className="w-full flex flex-col">
                      <Input
                        placeholderKey="*Firstname"
                        // labelKey="Firstname"
                        type="text"
                        {...register("firstname", {
                          required: "Your FirstName (required)",
                        })}
                        errorKey={errors.firstname?.message}
                        inputClassName="mb-0"
                      />
                      <Input
                        placeholderKey="*Lastname"
                        // labelKey="Lastname"
                        type="text"
                        {...register("lastname", {
                          required: "Your LastName (required)",
                        })}
                        errorKey={errors.lastname?.message}
                        inputClassName="mb-0"
                      />
                      <Input
                        placeholderKey="*Email"
                        // labelKey="Email"
                        type="email"
                        inputClassName="mb-0"
                        {...register("email", {
                          required: `${"Please provide your email address"}`,
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please provide a valid email address",
                          },
                        })}
                        errorKey={errors.email?.message}
                      />
                      <Input
                        placeholderKey="*Company Name"
                        type="text"
                        {...register("company", {
                          required: "Your Company Name (required)",
                        })}
                        errorKey={errors.company?.message}
                        inputClassName="mb-0"
                      />

                      <TextArea
                        placeholderKey="*Message"
                        type="text"
                        variant="solid"
                        {...register("message", {
                          required: "Your Message (required)",
                        })}
                        errorKey={errors.message?.message}
                      />
                      {error && <ErrorMessage>{error.message}</ErrorMessage>}
                      <SubmitButton
                        processing={processing}
                        error={error}
                        disabled={false}
                      >
                        Submit
                      </SubmitButton>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full h-full">
          <img
            src="/images/contact-banner.jpg"
            alt=""
            className="rounded-xl object-cover object-center w-full h-full"
          ></img>
          {/* <div className="bg-lightBlue rounded-full w-40 h-40 absolute top-0 -left-16 -z-1 opacity-60" /> */}
          {/* <div className="bg-lightBlue rounded-br-100px w-48 h-48 absolute -bottom-[100px] right-28 -z-1" /> */}
        </div>
      </div>

      {/* <div className="bg-[#fafafa] py-8 w-full flex flex-col justify-center items-center">
        <div className="w-full flex flex-col justify-center items-start relative z-0 lg:px-28 md:px-12 px-4 mb-6">
          <h1 className="text-left my-4 text-darkblue md:text-4xl text-2xl font-bold">
            Current Openings
          </h1>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 auto-rows-auto w-full md:mt-4">
            <Job />
          </div>
        </div>
      </div> */}
    </Layout>
  );
}
const Job = (props) => {
  return (
    <div className="rounded-2xl border-1 border-blue p-4 flex flex-col bg-white">
      <h3 className="text-[18px] font-bold text-darkblue leading-none">
        Director - Program – Migrants Resilience Collaborative
      </h3>
      <h4 className="text-black/80 font-medium mt-6">Location(s)</h4>
      <p className="text-base">
        Delhi, preferred. Work from home options available.
      </p>
      <div className="w-full h-[1px] bg-blue my-6"></div>
      <div className="flex justify-between w-full">
        <a
          href="https://jansahas.org/static/uploads/JD%20-%20Director%20-%20Program,%20MRC%20-%20Jan%20Sahas.pdf"
          target="_blank"
          rel="noreferrer"
          className="rounded-full px-4 py-2 border-1 border-blue text-base font-bold text-darkblue transition-all duration-300 ease hover:bg-blue hover:border-blue hover:text-white"
        >
          View job description
        </a>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSc8DXfGWaPejnQJurUu2OrQfHz6UApRDIR0Bxodbp2lNd4J6Q/viewform?usp=sf_link&urp=gmail_link"
          target="_blank"
          rel="noreferrer"
          className="rounded-full px-4 py-2 border-1 border-blue bg-blue text-base font-bold text-white transition-all duration-300 ease hover:bg-darkblue hover:border-darkblue hover:shadow-2xl hover:text-white"
        >
          Apply
        </a>
      </div>
    </div>
  );
};
