import React from "react";

const classes = {
    root:
        "bg-white text-sm text-gray-700 w-full h-11 md:h-12 pl-2 placeholder:text-sm placeholder:text-gray-400 placeholder:font-medium focus:outline-none",
};
const TextArea = React.forwardRef(
    (
        {
            className = "block mb-4",
            labelKey,
            name,
            errorKey,
            placeholderKey,
            variant = "normal",
            shadow = false,
            type = "text",
            inputClassName,
            ...rest
        },
        ref
    ) => {
        const rootClassName = classes.root + " " + (variant === "normal" ? classes.normal : variant === "solid" ? classes.solid : variant === "outline" ? classes.outline : "") + " focus:shadow " + inputClassName;

        return (
            <div className={className}>
                {labelKey && (
                    <label
                        htmlFor={name}
                        className="block font-normal leading-none mb-3 cursor-pointer"
                    >
                        {labelKey}
                    </label>
                )}
                <textarea
                    id={name}
                    name={name}
                    type={type}
                    ref={ref}
                    // @ts-ignore
                    placeholder={placeholderKey}
                    className="w-full text-sm text-gray-700 rounded-lg border-1 focus:outline-none h-40 md:h-40 pl-2 pt-2"
                    autoComplete="off"
                    spellCheck="false"
                    aria-invalid={errorKey ? "true" : "false"}
                    {...rest}
                />
                {errorKey && <p className="mt-2 text-xs text-red">{errorKey}</p>}
            </div>
        );
    }
);

export default TextArea;
